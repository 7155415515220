<template>
  <div class="animated fadeIn">
    <b-tabs
      v-model="tabIndex"
      justified
      tabs
    >
      <b-tab
        v-if="$store.getters.isContractor"
        title="Настройки оповещений"
        :active="active === 'notifications'"
        :title-link-class="linkClass(0)"
      >
        <contractor-notifications-settings />
      </b-tab>
      <b-tab
        v-if="$store.getters.isCustomer"
        title="Настройки оповещений"
        :active="active === 'notifications'"
        :title-link-class="linkClass(0)"
      >
        <customer-notifications-settings />
      </b-tab>
      <b-tab
        v-if="$store.getters.isCustomer"
        title="Настройки источников оповещений"
        :active="active === 'notificationsSources'"
      >
        <customer-listened-contractors />
        <customer-listened-route-owners />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ContractorNotificationsSettings from '../User/ContractorNotificationsSettings.vue';
import CustomerNotificationsSettings from './CustomerNotificationsSettings';
import {queryToUrl} from '../../services/http';
import CustomerListenedContractors from '../Notifications/Customer/CustomerListenedContractors';
import CustomerListenedRouteOwners from '../Notifications/Customer/CustomerListenedRouteOwners';

export default {
  name: 'UserSettings',
  components: {
    CustomerListenedRouteOwners,
    CustomerListenedContractors,
    CustomerNotificationsSettings,
    ContractorNotificationsSettings,
  },
  props: {
    'active': {
      type: String,
      default: 'notifications',
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  watch: {
    tabIndex() {
      const tab = queryToUrl('', {tab: this.tabIndex});
      history.pushState(null, null, tab);
    },

  },
  created() {
    const queries = this.$router.currentRoute.query;
    if (queries.tab) {
      this.tabIndex = Number(queries.tab);
    }
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['tab-height'];
      }
    }
  }
};
</script>

<style>
.tab-height {
  height: 100% !important;
}
</style>